import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'
import store from './store'

import Page from './views/Page.vue'
import NewsView from './views/NewsView.vue'

import Journals from './views/Journals.vue'
import Journal from './views/Journal.vue'
import IssuesList from './views/IssuesList.vue'
import IssueArticles from './views/IssueArticles.vue'
import ArticlesList from './views/ArticlesList.vue'
import ArticleView from './views/ArticleView.vue'

import AuthorsList from './views/AuthorsList.vue'
import AuthorView from './views/AuthorView.vue'

import JournalCategory from './components/JournalCategory.vue'

import ArticlesFilter from './components/filters/ArticlesFilter.vue'
import IssueFilter from './components/filters/IssueFilter.vue'
import AuthorsFilter from './components/filters/AuthorsFilter.vue'

import Login from './views/Login.vue'
import Profile from './views/Profile.vue'

import initItems from "./index.js"

Vue.use(VueRouter)
Vue.use(VueMeta, {
	refreshOnceOnNavigation: true
})

const fb = require('./firebaseConfig.js')

const routes = [
    {
        path: '*',
        redirect: '/home',
        meta: {
            scrollBehavior: () => ({x: 0, y: 0})
        }
    },
    {
        path: '/:lang?/home',
        name: 'home',
        component: Journals,
        meta: {
            scrollBehavior: () => ({x: 0, y: 0})
        },
        children: [
            {
                path: '',
                component:  IssuesList,
                props: { category: () => ({selectedRoute: 'issues' }) }
            },
        ]
    },
    {
        name: 'page',
        path: '/:lang?/page/:href',
        component: Page,
        children: [{
            path: '*',
            component: Page
        }],
        meta: {
            scrollBehavior: () => ({x: 0, y: 0})
        }
    },
    {
        name: 'news',
        path: '/:lang?/news',
        component: NewsView,
        meta: {
            scrollBehavior: () => ({x: 0, y: 0})
        }
    },
    {
        path: '/:lang?/journals',
        name: 'journals',
        component: Journals,
        meta: {
            scrollBehavior: () => ({x: 0, y: 0})
        }
    },
    // {
    //     path: '/journal/:issn/details',
    //     component: Page
    // },
    {
        path: '/:lang?/journal/:issn',
        name: 'journal',
        component: Journal,
        meta: {
            scrollBehavior: () => ({x: 0, y: 0})
        },
        children: [
            {
                path: 'details/',
                components: {
                    default: Page,
                    category: JournalCategory
                }
            },
            {
                path: '',
                components: {
                    default: IssuesList,
                    filter: IssueFilter,
                    category: JournalCategory
                },
                props: { category: () => ({selectedRoute: 'issues' }) }
            },
            {
                path: 'issues',
                components: {
                    default: IssuesList,
                    filter: IssueFilter,
                    category: JournalCategory
                },
                props: { category: () => ({selectedRoute: 'issues' }) }
            },
            {
                path: 'issue/:issueId',
                components: {
                    default: IssueArticles,
                    // filter: IssueFilter,
                    category: JournalCategory
                },
                props: { category: () => ({selectedRoute: 'issues' }) }
            },
            {
                path: 'articles',
                components: {
                    default: ArticlesList,
                    filter: ArticlesFilter,
                    category: JournalCategory
                },
                props: { category: () => ({ selectedRoute: 'articles' }) },
                meta: {
                    scrollBehavior: () => ({x: 0, y: 0})
                }
            },
            {
                path: 'authors',
                components: {
                    default: AuthorsList,
                    filter: AuthorsFilter,
                    category: JournalCategory
                },
                props: { category: () => ({selectedRoute: 'authors' }) }
            },
            {
                path: 'authors/:authorId',
                components: {
                    default: AuthorView,
                    filter: AuthorsFilter,
                    category: JournalCategory
                },
                props: { category: () => ({selectedRoute: 'authors' }) }
            }
                
        ]
    },
    {
        path: '/:lang?/article/:articleId',
        name: 'article',
        component: ArticleView,
        meta: {
            scrollBehavior: () => ({x: 0, y: 0})
        }
    },
    {
        path: '/:lang?/login',
        name: 'login',
        component: Login,
        meta: {
            scrollBehavior: () => ({x: 0, y: 0})
        }
    },
    {
        path: '/:lang?/profile',
        name: 'profile',
        component: Profile,
        meta: {
            scrollBehavior: () => ({x: 0, y: 0}),
            requiresAuth: true
        }
    }
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
    scrollBehavior(to, from, savedPosition) {
        if (to.meta.scrollBehavior) {
            return to.meta.scrollBehavior()
        }
        return savedPosition
	}
})


router.beforeEach((to, from, next) => {
    
    const { lang } = to.params;
    store.dispatch('setLanguage', lang)
    if(!lang || lang != store.getters.settings.lang) {
        let { params, query, fullPath} = to
        params.lang = store.getters.settings.lang
        console.log('force change language', lang, store.getters.settings.lang, params, query)
        next({params, query, path: `/${params.lang}${fullPath}`})
        return
    }

    const requiresAuth = to.matched.some(x => x.meta.requiresAuth)
    const currentUser = fb.auth.currentUser
    if (requiresAuth && !currentUser) {
        if(to.fullPath.indexOf('/app') == 0) {
            next(`/${to.params.lang}/login`)
        } else {
            next(`/${to.params.lang}/home`)
        }
    } else if (requiresAuth && currentUser) {
        next()
    } else {
        next()
    }
})

router.afterEach((to) => {
    Vue.nextTick(() => {
        const instance = to.matched[0].instances.default
        window.document.title = instance.doc.meta.title + ' - ' + instance.translation.title;
        initItems()
    });
});


export default router
