import Vue from 'vue'
import App from './App.vue'

import router from './router'
import store from './store'

import './sass/main.scss'
import '../../../content/assets/files/custom.scss'

import hreflang from '@/lib/hreflang'
import metatext from '@/lib/metatext'
import _ from 'lodash'

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(fas)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.use(hreflang)
Vue.use(metatext)
Vue.use({
  install : function(Vue){
      Vue.mixin({
          computed : {
              "_" : function(){
                  return _;
              }
          }
      });
  }
})

const fb = require('./firebaseConfig.js')

let app
fb.auth.onAuthStateChanged(async user => {
  if (!app) {
    app = new Vue({
      router,
      store,
      render: h => h(App)
    }).$mount('#app')
  }
  
  await store.dispatch('init')
  await store.dispatch('selectUser', user ? user.uid : null)
})
